import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  PaymentIntent,
  PaymentRequest,
  PaymentRequestWallet,
} from "@stripe/stripe-js";
import { Plan, useGeneratedStripePaymentIntentQuery } from "services/graphql";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import { useApplePayFlag, useGooglePayFlag } from "hooks/useFeatureFlags";

interface Props {
  onSubmit: ({
    paymentIntent,
    walletName,
  }: {
    paymentIntent: PaymentIntent;
    walletName: string;
  }) => void;
  onError: () => void;
  selectedPlan: Plan;
  paymentRequest: PaymentRequest | null;
  setPaymentRequest(paymentRequest: PaymentRequest | null): void;
}

export function PaymentRequestButtons(props: Props) {
  const isGooglePayAvailable = useGooglePayFlag();
  const isApplePayAvailable = useApplePayFlag();

  const arePaymentProvidersAvailable =
    isGooglePayAvailable || isApplePayAvailable;

  if (!arePaymentProvidersAvailable) {
    return null;
  }

  return <PaymentRequestButtonsComponent {...props} />;
}

export function PaymentRequestButtonsComponent({
  selectedPlan,
  onSubmit,
  onError,
  paymentRequest,
  setPaymentRequest,
}: Props) {
  const stripe = useStripe();
  const { hasTrialed } = useUserHasTrialed();
  const {
    data: generateStripePaymentIntentData,
  } = useGeneratedStripePaymentIntentQuery({
    variables: {
      amountInCents: selectedPlan.priceInCents,
    },
  });

  const isGooglePayAvailable = useGooglePayFlag();
  const isApplePayAvailable = useApplePayFlag();

  const disableWallets: PaymentRequestWallet[] = [];
  if (!isGooglePayAvailable) {
    disableWallets.push("googlePay");
  }
  if (!isApplePayAvailable) {
    disableWallets.push("applePay");
  }

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const displayItems = [];

    const { canTrial, trialPeriod, trialPeriodUnit } = selectedPlan;
    let displayAmount = selectedPlan.priceInCents;

    if (canTrial && !hasTrialed) {
      const capitalizedTrialPeriodUnit =
        (trialPeriodUnit || "")?.charAt(0).toUpperCase() +
          trialPeriodUnit?.slice(1) || "Days";

      displayItems.push({
        amount: 0,
        label: `${trialPeriod}-${capitalizedTrialPeriodUnit} Free Trial. You will not be charged until your trial ends.`,
      });
      displayAmount = 0;
    }

    if (!generateStripePaymentIntentData) {
      return;
    }

    const { generateStripePaymentIntent } = generateStripePaymentIntentData;

    if (!generateStripePaymentIntent) {
      return;
    }

    const {
      id: stripePaymentIntentId,
      clientSecret,
    } = generateStripePaymentIntent;

    if (!stripePaymentIntentId) {
      return;
    }

    const stripePaymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: `STEEZY Studio ${selectedPlan.name}`,
        amount: displayAmount,
      },
      displayItems,
      requestPayerEmail: true,
      disableWallets,
    });

    stripePaymentRequest.canMakePayment().then(result => {
      if (result) {
        // If Google Pay is available and enabled, prioritize it by disabling Apple Pay
        if (result.googlePay || disableWallets.indexOf("googlePay") === -1) {
          setPaymentRequest(stripePaymentRequest);
        } else if (
          result.applePay &&
          disableWallets.indexOf("applePay") === -1
        ) {
          // If Google Pay is not available or enabled, but Apple Pay is, enable Apple Pay
          setPaymentRequest(stripePaymentRequest);
        }
      } else {
        setPaymentRequest(null);
      }
    });

    stripePaymentRequest.on("paymentmethod", async ev => {
      const { walletName } = ev;
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
      if (confirmError) {
        onError();
        ev.complete("fail");
      } else {
        ev.complete("success");
        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            onError();
          } else {
            onSubmit({ paymentIntent, walletName });
          }
        } else {
          onSubmit({ paymentIntent, walletName });
        }
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (stripePaymentRequest) {
        stripePaymentRequest.off("paymentmethod");
      }
    };
  }, [selectedPlan, stripe, generateStripePaymentIntentData]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }

  return null;
}
