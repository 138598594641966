export enum DateStrFormat {
  MMMDDYYYY = "MMMDDYYYY", // "Dec 22 2022"
  MMMDD_YYYY = "MMMDD_YYYY", // "Dec 22, 2022"
  MMDDYYYY = "MMDDYYYY", // "12/17/2022"
}

export function getDateString(format: DateStrFormat, epochMS?: number | null) {
  const date = epochMS ? new Date(epochMS) : new Date();

  let out;

  switch (format) {
    case DateStrFormat.MMMDDYYYY:
      out = date.toDateString();
      return out.substring(out.indexOf(" ") + 1);
    case DateStrFormat.MMMDD_YYYY:
      out = date.toDateString();
      out = out.substring(out.indexOf(" ") + 1);
      // eslint-disable-next-line no-case-declarations
      const [month, day, year] = out.split(" ");
      return `${month} ${day}, ${year}`;
    case DateStrFormat.MMDDYYYY:
      return date.toLocaleDateString();
    default:
      throw new Error();
  }
}
