import { GatewayProvider, PaymentMethodType } from "services/graphql";
import { PaymentIntent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useCheckout } from "modules/selectors";
import { CheckoutError } from "constants/cart";
import { useCreateSubscription } from "./useCreateSubscription";

const PaymentMethodToType = {
  googlePay: PaymentMethodType.GooglePay,
  applePay: PaymentMethodType.ApplePay,
};

interface Props {
  onSuccess?(): void;
}

export function useCheckoutWithWallet(props?: Props) {
  const { onSuccess } = props || {};
  const { selectedPlan } = useCheckout();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { createSubscription } = useCreateSubscription({ onSuccess });

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  async function checkoutWallet({
    paymentIntent,
    walletName,
  }: {
    paymentIntent: PaymentIntent;
    walletName: string;
  }) {
    if (!(paymentIntent && selectedPlan)) {
      setError(CheckoutError.Generic);
      return;
    }

    const paymentMethodType =
      PaymentMethodToType[walletName as keyof typeof PaymentMethodToType];

    if (!paymentMethodType) {
      setError(CheckoutError.PaymentMethod);
      return;
    }

    try {
      setLoading(true);

      try {
        await createSubscription({
          gatewayProvider: GatewayProvider.Stripe,
          paymentMethodType,
          token: paymentIntent.id,
        });
      } catch (e) {
        setError(e as any);
      }
    } catch (e) {
      setError(CheckoutError.SubscriptionCreate);
    }
  }

  return {
    wallet: {
      error,
      loading,
      checkoutWallet,
    },
  };
}
