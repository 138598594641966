import React from "react";
import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { P2 } from "app/components/Typography";
import Div from "app/components/Div";
import Loader from "app/components/Loader";
import env from "helpers/env";
import { OnApproveData } from "@paypal/paypal-js";

interface Props {
  onError: () => void;
  createBillingAgreement: () => Promise<string>;
  onApprove: (data: OnApproveData) => Promise<void>;
  loading?: boolean;
  error?: any;
}

export const PaypalButton = ({
  loading,
  error,
  onError,
  createBillingAgreement,
  onApprove,
}: Props) => {
  if (env("PUBLIC_PAYPAL_ENABLED") === "false") {
    return null;
  }

  return (
    <Div m={0} maxWidth="100%">
      {error && (
        <P2 color="red">
          Could not connect to PayPal. Please refresh the page and try again.
        </P2>
      )}
      {loading ? (
        <Loader width={24} margin="0 auto" />
      ) : (
        <PayPalButtons
          style={{ height: 50 }}
          fundingSource={FUNDING.PAYPAL}
          createBillingAgreement={createBillingAgreement}
          onApprove={onApprove}
          onError={onError}
        />
      )}
    </Div>
  );
};
