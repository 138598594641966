import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import Button from "app/components/Button";
import { P2 } from "app/components/Typography";
import styles from "./styles.module.scss";

type ButtonProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { disclaimer?: JSX.Element | string; disabled?: boolean };

export function SubmitButton({
  children,
  disclaimer,
  disabled,
  onClick,
}: ButtonProps) {
  return (
    <div className={styles.submit}>
      <Button onClick={onClick} disabled={disabled}>
        {children}
      </Button>
      <P2 style={{ fontSize: "14px" }}>{disclaimer}</P2>
    </div>
  );
}
